/*
   this file is generated automatically by searxng_extra/update/update_pygments.py
   using pygments version 2.18.0
*/


.code-highlight {

  pre { line-height: 100%; }
  td.linenos .normal { color: inherit; background-color: transparent; padding-left: 5px; padding-right: 5px; }
  span.linenos { color: inherit; background-color: transparent; padding-left: 5px; padding-right: 5px; }
  td.linenos .special { color: #000000; background-color: #ffffc0; padding-left: 5px; padding-right: 5px; }
  span.linenos.special { color: #000000; background-color: #ffffc0; padding-left: 5px; padding-right: 5px; }
  .hll { background-color: #ffffcc }
  .c { color: #3D7B7B; font-style: italic } /* Comment */
  .err { border: 1px solid #FF0000 } /* Error */
  .k { color: #008000; font-weight: bold } /* Keyword */
  .o { color: #666666 } /* Operator */
  .ch { color: #3D7B7B; font-style: italic } /* Comment.Hashbang */
  .cm { color: #3D7B7B; font-style: italic } /* Comment.Multiline */
  .cp { color: #9C6500 } /* Comment.Preproc */
  .cpf { color: #3D7B7B; font-style: italic } /* Comment.PreprocFile */
  .c1 { color: #3D7B7B; font-style: italic } /* Comment.Single */
  .cs { color: #3D7B7B; font-style: italic } /* Comment.Special */
  .gd { color: #A00000 } /* Generic.Deleted */
  .ge { font-style: italic } /* Generic.Emph */
  .ges { font-weight: bold; font-style: italic } /* Generic.EmphStrong */
  .gr { color: #E40000 } /* Generic.Error */
  .gh { color: #000080; font-weight: bold } /* Generic.Heading */
  .gi { color: #008400 } /* Generic.Inserted */
  .go { color: #717171 } /* Generic.Output */
  .gp { color: #000080; font-weight: bold } /* Generic.Prompt */
  .gs { font-weight: bold } /* Generic.Strong */
  .gu { color: #800080; font-weight: bold } /* Generic.Subheading */
  .gt { color: #0044DD } /* Generic.Traceback */
  .kc { color: #008000; font-weight: bold } /* Keyword.Constant */
  .kd { color: #008000; font-weight: bold } /* Keyword.Declaration */
  .kn { color: #008000; font-weight: bold } /* Keyword.Namespace */
  .kp { color: #008000 } /* Keyword.Pseudo */
  .kr { color: #008000; font-weight: bold } /* Keyword.Reserved */
  .kt { color: #B00040 } /* Keyword.Type */
  .m { color: #666666 } /* Literal.Number */
  .s { color: #BA2121 } /* Literal.String */
  .na { color: #687822 } /* Name.Attribute */
  .nb { color: #008000 } /* Name.Builtin */
  .nc { color: #0000FF; font-weight: bold } /* Name.Class */
  .no { color: #880000 } /* Name.Constant */
  .nd { color: #AA22FF } /* Name.Decorator */
  .ni { color: #717171; font-weight: bold } /* Name.Entity */
  .ne { color: #CB3F38; font-weight: bold } /* Name.Exception */
  .nf { color: #0000FF } /* Name.Function */
  .nl { color: #767600 } /* Name.Label */
  .nn { color: #0000FF; font-weight: bold } /* Name.Namespace */
  .nt { color: #008000; font-weight: bold } /* Name.Tag */
  .nv { color: #19177C } /* Name.Variable */
  .ow { color: #AA22FF; font-weight: bold } /* Operator.Word */
  .w { color: #bbbbbb } /* Text.Whitespace */
  .mb { color: #666666 } /* Literal.Number.Bin */
  .mf { color: #666666 } /* Literal.Number.Float */
  .mh { color: #666666 } /* Literal.Number.Hex */
  .mi { color: #666666 } /* Literal.Number.Integer */
  .mo { color: #666666 } /* Literal.Number.Oct */
  .sa { color: #BA2121 } /* Literal.String.Affix */
  .sb { color: #BA2121 } /* Literal.String.Backtick */
  .sc { color: #BA2121 } /* Literal.String.Char */
  .dl { color: #BA2121 } /* Literal.String.Delimiter */
  .sd { color: #BA2121; font-style: italic } /* Literal.String.Doc */
  .s2 { color: #BA2121 } /* Literal.String.Double */
  .se { color: #AA5D1F; font-weight: bold } /* Literal.String.Escape */
  .sh { color: #BA2121 } /* Literal.String.Heredoc */
  .si { color: #A45A77; font-weight: bold } /* Literal.String.Interpol */
  .sx { color: #008000 } /* Literal.String.Other */
  .sr { color: #A45A77 } /* Literal.String.Regex */
  .s1 { color: #BA2121 } /* Literal.String.Single */
  .ss { color: #19177C } /* Literal.String.Symbol */
  .bp { color: #008000 } /* Name.Builtin.Pseudo */
  .fm { color: #0000FF } /* Name.Function.Magic */
  .vc { color: #19177C } /* Name.Variable.Class */
  .vg { color: #19177C } /* Name.Variable.Global */
  .vi { color: #19177C } /* Name.Variable.Instance */
  .vm { color: #19177C } /* Name.Variable.Magic */
  .il { color: #666666 } /* Literal.Number.Integer.Long */
}

.code-highlight-dark(){
  .code-highlight {

    pre { line-height: 100%; }
    td.linenos .normal { color: #3c4354; background-color: transparent; padding-left: 5px; padding-right: 5px; }
    span.linenos { color: #3c4354; background-color: transparent; padding-left: 5px; padding-right: 5px; }
    td.linenos .special { color: #3c4354; background-color: #ffffc0; padding-left: 5px; padding-right: 5px; }
    span.linenos.special { color: #3c4354; background-color: #ffffc0; padding-left: 5px; padding-right: 5px; }
    .hll { background-color: #6e7681 }
    .c { color: #7e8aa1 } /* Comment */
    .err { color: #f88f7f } /* Error */
    .esc { color: #d4d2c8 } /* Escape */
    .g { color: #d4d2c8 } /* Generic */
    .k { color: #FFAD66 } /* Keyword */
    .l { color: #D5FF80 } /* Literal */
    .n { color: #d4d2c8 } /* Name */
    .o { color: #FFAD66 } /* Operator */
    .x { color: #d4d2c8 } /* Other */
    .p { color: #d4d2c8 } /* Punctuation */
    .ch { color: #f88f7f; font-style: italic } /* Comment.Hashbang */
    .cm { color: #7e8aa1 } /* Comment.Multiline */
    .cp { color: #FFAD66; font-weight: bold } /* Comment.Preproc */
    .cpf { color: #7e8aa1 } /* Comment.PreprocFile */
    .c1 { color: #7e8aa1 } /* Comment.Single */
    .cs { color: #7e8aa1; font-style: italic } /* Comment.Special */
    .gd { color: #f88f7f; background-color: #3d1e20 } /* Generic.Deleted */
    .ge { color: #d4d2c8; font-style: italic } /* Generic.Emph */
    .ges { color: #d4d2c8 } /* Generic.EmphStrong */
    .gr { color: #f88f7f } /* Generic.Error */
    .gh { color: #d4d2c8 } /* Generic.Heading */
    .gi { color: #6ad4af; background-color: #19362c } /* Generic.Inserted */
    .go { color: #7e8aa1 } /* Generic.Output */
    .gp { color: #d4d2c8 } /* Generic.Prompt */
    .gs { color: #d4d2c8; font-weight: bold } /* Generic.Strong */
    .gu { color: #d4d2c8 } /* Generic.Subheading */
    .gt { color: #f88f7f } /* Generic.Traceback */
    .kc { color: #FFAD66 } /* Keyword.Constant */
    .kd { color: #FFAD66 } /* Keyword.Declaration */
    .kn { color: #FFAD66 } /* Keyword.Namespace */
    .kp { color: #FFAD66 } /* Keyword.Pseudo */
    .kr { color: #FFAD66 } /* Keyword.Reserved */
    .kt { color: #73D0FF } /* Keyword.Type */
    .ld { color: #D5FF80 } /* Literal.Date */
    .m { color: #DFBFFF } /* Literal.Number */
    .s { color: #D5FF80 } /* Literal.String */
    .na { color: #FFD173 } /* Name.Attribute */
    .nb { color: #FFD173 } /* Name.Builtin */
    .nc { color: #73D0FF } /* Name.Class */
    .no { color: #FFD173 } /* Name.Constant */
    .nd { color: #7e8aa1; font-weight: bold; font-style: italic } /* Name.Decorator */
    .ni { color: #95E6CB } /* Name.Entity */
    .ne { color: #73D0FF } /* Name.Exception */
    .nf { color: #FFD173 } /* Name.Function */
    .nl { color: #d4d2c8 } /* Name.Label */
    .nn { color: #d4d2c8 } /* Name.Namespace */
    .nx { color: #d4d2c8 } /* Name.Other */
    .py { color: #FFD173 } /* Name.Property */
    .nt { color: #5CCFE6 } /* Name.Tag */
    .nv { color: #d4d2c8 } /* Name.Variable */
    .ow { color: #FFAD66 } /* Operator.Word */
    .pm { color: #d4d2c8 } /* Punctuation.Marker */
    .w { color: #d4d2c8 } /* Text.Whitespace */
    .mb { color: #DFBFFF } /* Literal.Number.Bin */
    .mf { color: #DFBFFF } /* Literal.Number.Float */
    .mh { color: #DFBFFF } /* Literal.Number.Hex */
    .mi { color: #DFBFFF } /* Literal.Number.Integer */
    .mo { color: #DFBFFF } /* Literal.Number.Oct */
    .sa { color: #F29E74 } /* Literal.String.Affix */
    .sb { color: #D5FF80 } /* Literal.String.Backtick */
    .sc { color: #D5FF80 } /* Literal.String.Char */
    .dl { color: #D5FF80 } /* Literal.String.Delimiter */
    .sd { color: #7e8aa1 } /* Literal.String.Doc */
    .s2 { color: #D5FF80 } /* Literal.String.Double */
    .se { color: #95E6CB } /* Literal.String.Escape */
    .sh { color: #D5FF80 } /* Literal.String.Heredoc */
    .si { color: #95E6CB } /* Literal.String.Interpol */
    .sx { color: #95E6CB } /* Literal.String.Other */
    .sr { color: #95E6CB } /* Literal.String.Regex */
    .s1 { color: #D5FF80 } /* Literal.String.Single */
    .ss { color: #DFBFFF } /* Literal.String.Symbol */
    .bp { color: #5CCFE6 } /* Name.Builtin.Pseudo */
    .fm { color: #FFD173 } /* Name.Function.Magic */
    .vc { color: #d4d2c8 } /* Name.Variable.Class */
    .vg { color: #d4d2c8 } /* Name.Variable.Global */
    .vi { color: #d4d2c8 } /* Name.Variable.Instance */
    .vm { color: #d4d2c8 } /* Name.Variable.Magic */
    .il { color: #DFBFFF } /* Literal.Number.Integer.Long */
  }
}
